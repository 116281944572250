import { IGetBoardGamesQuery } from '#graphql/graphql'
import { useUserData } from '@nhost/react'
import { FC } from 'react'

import { StatsDonut } from '../StatsDonut'

interface ISideNav {
  user?: IGetBoardGamesQuery['user']
}

export const StatsBar: FC<ISideNav> = ({ user }) => {
  const userData = useUserData()
  if (!user) user = userData

  return <StatsDonut userUuid={user?.id} />
}
