// ScreenSizeContext.tsx
import { useMediaQuery } from '@mantine/hooks'
import {
  FC,
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react'

// Define an enum for screen sizes
export enum ScreenSizes {
  LG = 'lg',
  MD = 'md',
  SM = 'sm',
  XL = 'xl',
  XS = 'xs',
}

export interface IScreenSettings {
  isMobile: boolean
  screenSize: ScreenSizes
}

// const screenSettings = {screenScreenSizes}

// Create a context with a default value
const ScreenSizeContext = createContext<IScreenSettings>({
  isMobile: false,
  screenSize: ScreenSizes.XS,
})

interface ScreenSizeProviderProperties {
  children: ReactNode
}

export const ScreenSizeProvider: FC<ScreenSizeProviderProperties> = ({
  children,
}) => {
  const [screenSize, setScreenSize] = useState<ScreenSizes>(ScreenSizes.XS)

  // Media queries; make sure this matches ~/lib/_mantine.scss
  const isXs = useMediaQuery('(max-width: 36em)')
  const isSm = useMediaQuery('(max-width: 48em)')
  const isMd = useMediaQuery('(max-width: 62em)')
  const isLg = useMediaQuery('(max-width: 75em)')
  const [isMobile, setIsMobile] = useState(false)
  useEffect(() => {
    if (isXs) setScreenSize(ScreenSizes.XS)
    else if (isSm) setScreenSize(ScreenSizes.SM)
    else if (isMd) setScreenSize(ScreenSizes.MD)
    else if (isLg) setScreenSize(ScreenSizes.LG)
    else setScreenSize(ScreenSizes.XL)

    setIsMobile(window.innerWidth <= 480)
  }, [isXs, isSm, isMd, isLg])

  return (
    <ScreenSizeContext.Provider value={{ isMobile, screenSize: screenSize }}>
      {children}
    </ScreenSizeContext.Provider>
  )
}

// Custom hook to use the context
export const useScreenSize = (): IScreenSettings =>
  useContext(ScreenSizeContext)
