import { useAutoAnimate } from '@formkit/auto-animate/react'
import { UnstyledButton } from '@mantine/core'
import { Markup } from 'interweave'
import { useState } from 'react'

import Styles from './styles.module.scss'

interface IReadMore {
  amountOfWords?: number
  id: string
  text: string
}

export const ReadMore = ({ amountOfWords = 50, id, text }: IReadMore) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const splittedText = text.split(' ')
  const itCanOverflow = splittedText.length > amountOfWords
  const beginText = itCanOverflow
    ? splittedText.slice(0, amountOfWords - 1).join(' ')
    : text
  const endText = '&nbsp;' + splittedText.slice(amountOfWords - 1).join(' ')
  const [animationParent] = useAutoAnimate()

  return (
    <div>
      <div ref={animationParent}>
        <span>
          <Markup content={beginText} />
          {!isExpanded && '...'}
        </span>
        {isExpanded && <Markup content={endText} />}
      </div>
      <UnstyledButton
        aria-controls={id}
        aria-expanded={isExpanded}
        className={Styles.expander}
        onClick={() => setIsExpanded(!isExpanded)}
        tabIndex={0}
        type={'button'}
      >
        {isExpanded ? 'show less' : 'show more'}
      </UnstyledButton>
    </div>
  )
}
