import { useGetMechanicPercentagesForUserQuery } from '#graphql/graphql'
import { DonutChart } from '@mantine/charts'
import { ColorSwatch, Skeleton, Table } from '@mantine/core'
import { useUserId } from '@nhost/react'
import { FC, ReactNode } from 'react'
import { useDebounceCallback } from 'usehooks-ts'

import { COLORS, consolidateData } from './helpers'
import Styles from './styles.module.scss'

export interface IStatsDonut {
  showLegend?: boolean
  userUuid?: string
}

export const StatsDonut: FC<IStatsDonut> = ({
  showLegend = true,
  userUuid,
}) => {
  const uuid = useUserId()

  if (!userUuid) userUuid = uuid

  const { data, error, isLoading, refetch } =
    useGetMechanicPercentagesForUserQuery({
      percentageThreshold: 0,
      userUuid: userUuid,
    })

  const debouncedRefetch = useDebounceCallback(refetch, 1000)

  if (error) debouncedRefetch()

  const consolidatedData = consolidateData(data)
  const pieData = consolidatedData.map((item, index) => ({
    color: COLORS[index],
    name: item.mechanic ?? '',
    value: item.count,
  }))

  const rows = consolidatedData?.map((element, index) => (
    <Table.Tr key={element.mechanic}>
      <Table.Td>
        <ColorSwatch
          color={`var(--mantine-color-${COLORS[index]}-9)`}
          radius={'md'}
        />
      </Table.Td>
      <Table.Td>{element.mechanic}</Table.Td>
      <Table.Td>{element.count}</Table.Td>
    </Table.Tr>
  ))

  const skeletonRows = consolidatedData?.map((element) => (
    <Table.Tr key={element.mechanic}>
      <Table.Td>
        <Skeleton
          height={'2em'}
          radius={'md'}
          width={'2em'}
        />
      </Table.Td>
      <Table.Td>
        <Skeleton
          height={'1em'}
          radius={'md'}
        />
      </Table.Td>
      <Table.Td>
        <Skeleton
          height={'1em'}
          radius={'md'}
        />
      </Table.Td>
    </Table.Tr>
  ))

  const renderTable = (tableRows: ReactNode[]) => (
    <div className={Styles.legend}>
      <Table>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>Color</Table.Th>
            <Table.Th>Category</Table.Th>
            <Table.Th>Amount</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>{tableRows}</Table.Tbody>
      </Table>
    </div>
  )
  const renderSkeleton = () => (
    <div className={Styles.chartContainer}>
      <Skeleton
        circle
        height={150}
        mb="xl"
      />
      {showLegend && renderTable(skeletonRows)}
    </div>
  )

  const renderDonut = () => (
    <div className={Styles.chartContainer}>
      <h1>Stats</h1>
      <div className={Styles.chart}>
        <DonutChart
          data={pieData ?? []}
          mx="auto"
          tooltipDataSource="segment"
        />
      </div>
      {showLegend && renderTable(rows)}
    </div>
  )

  return isLoading ? renderSkeleton() : renderDonut()
}
