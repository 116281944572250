import {Header} from '#components/Header'
import {SideNav} from '#components/SideNav'
import {FC, ReactElement, ReactNode} from 'react'

import Styles from './styles.module.scss'

interface ISidebarAndMain {
  mainContent: ReactElement
  sidebarContent?: ReactElement | ReactNode
}

export const TopNavWithSidebarAndMain: FC<ISidebarAndMain> = ({
  mainContent,
  sidebarContent,
}) => {
  return (
    <div className={Styles.wrapper}>
      <Header/>
      <div className={Styles.content}>
        {sidebarContent && (
          <div className={Styles.aside}>
            <SideNav>{sidebarContent}</SideNav>
          </div>
        )}
        <div className={Styles.main}>{mainContent}</div>
      </div>
    </div>
  )
}
