export const findMostFrequent = (data: string[]): string | undefined => {
  if (!data || data.length === 0) return ''

  const counter = new Map<string, number>()
  for (const element of data) {
    if (counter.has(element)) {
      // @ts-expect-error counter is initialized...
      counter.set(element, counter.get(element) + 1)
    } else {
      counter.set(element, 1)
    }
  }

  let maxCount = 0
  let mostFrequent: string | undefined
  for (const [key, value] of counter) {
    if (value > maxCount) {
      maxCount = value
      mostFrequent = key
    }
  }

  return mostFrequent
}
export const getOverlayColor = (scans: number) => {
  if (scans >= 9) return '#45f500'

  if (scans >= 7) return '#8aff5c'

  if (scans >= 5) return '#ebff5c'

  if (scans >= 3) return '#ffcd54'

  if (scans >= 1) return '#fc6a08'

  return '#ff0000'
}
