import { LibraryWithScrollTracking } from '#components/Library'
import { StatsBar } from '#components/StatsBar'
import { UserCard } from '#components/UserCard'
import { ScreenSizes, useScreenSize } from '#contexts/ScreenSizeContext'
import { useGetBoardGamesQuery } from '#graphql/graphql'
import { TopNavWithSidebarAndMain } from '#layouts/TopNavWithSidebarAndMain'
import Styles from '#screens/UserScreen/styles.module.scss'
import { useParams } from '@tanstack/react-router'

export const UserScreen = () => {
  const { screenSize } = useScreenSize()
  const screenSizeTooSmall = [ScreenSizes.SM, ScreenSizes.XS].includes(
    screenSize
  )
  const { userId } = useParams({ from: '/_public/users/$userId' })
  const { data, error, isLoading } = useGetBoardGamesQuery({ uuid: userId })

  if (isLoading) return null

  if (!data) return null

  if (error) console.error(error)

  const sideBarContent = () => {
    if (screenSizeTooSmall) return null

    if (!data.user) return

    return (
      <div className={Styles.sideBarContent}>
        <UserCard
          avatarUrl={data.user.avatarUrl}
          displayName={data.user.displayName}
          gamesCount={data.boardGames.length}
          id={data.user.id}
        />
        <StatsBar user={data.user} />
      </div>
    )
  }

  return (
    <TopNavWithSidebarAndMain
      mainContent={<LibraryWithScrollTracking data={data} />}
      sidebarContent={sideBarContent()}
    />
  )
}
