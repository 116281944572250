import { useGetUsersWithBoardGamesQuery } from '#graphql/graphql'

import { UserCard } from '../UserCard'
import Styles from './styles.module.scss'

export const UsersWithBoardGames = () => {
  const { data, error, isLoading } = useGetUsersWithBoardGamesQuery()

  if (isLoading) return null

  if (!data) return null

  if (error) console.log(error)

  return (
    <div className={Styles.userCards}>
      {data.users.map((user) => (
        <UserCard
          avatarUrl={user.avatarUrl}
          displayName={user.displayName}
          gamesCount={user?.boardGames_aggregate?.aggregate?.count ?? 0}
          id={user.id}
          key={user.id}
        />
      ))}
    </div>
  )
}
