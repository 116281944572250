import { ILookupGameQuery, Maybe } from '#graphql/graphql'
import { Carousel } from '@mantine/carousel'
import { Button, Card, Image, Text } from '@mantine/core'
import { motion } from 'framer-motion'
import { FC } from 'react'

import Styles from './styles.module.scss'

interface ISearchResults {
  isLoading: boolean
  onSelect: (id: Maybe<number> | undefined) => void
  results: NonNullable<NonNullable<ILookupGameQuery['lookupGame']>['results']>
}

export const SearchResults: FC<ISearchResults> = ({
  isLoading,
  onSelect,
  results,
}) => {
  if (!results || results.length === 0) return null

  const cards = results.map((result) => (
    <Carousel.Slide
      key={result?.id}
      style={{ paddingTop: '1em' }}
    >
      <motion.div
        whileHover={{
          scale: 1.05,
          transition: {
            damping: 20,
            duration: 0.2,
            stiffness: 500,
            type: 'spring',
          },
        }}
      >
        <Card
          className={Styles.card}
          padding="lg"
          radius="md"
          shadow="sm"
          withBorder
        >
          <Card.Section>
            <motion.div>
              <Image
                alt={result?.name ?? ''}
                fit="contain"
                h={150}
                src={result?.thumbnail_url}
              />
            </motion.div>
          </Card.Section>
          <Text
            lineClamp={2}
            size={'md'}
            ta={'center'}
          >
            {result?.name}
          </Text>
          <Button
            color="blue"
            fullWidth
            loading={isLoading}
            mt="md"
            onClick={() => onSelect(result?.id)}
            radius="md"
          >
            Add Game
          </Button>
        </Card>
      </motion.div>
    </Carousel.Slide>
  ))

  return (
    <Carousel
      align="start"
      controlSize={30}
      height={340}
      slideGap="xl"
      slideSize="30%"
      withIndicators
    >
      {cards}
    </Carousel>
  )
}
