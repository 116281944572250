import { findMostFrequent, getOverlayColor } from '#components/BScan/helpers'
import { useScreenSize } from '#contexts/ScreenSizeContext'
import { Alert, Button, Group, Slider, Text } from '@mantine/core'
import {
  IconAlertCircle,
  IconBulb,
  IconBulbOff,
  IconCamera,
  IconInfoCircle,
} from '@tabler/icons-react'
import { FC, useCallback, useEffect, useRef, useState } from 'react'

import Styles from './styles.module.scss'
import { ICallback, useQr } from './use-qr' // 16:9 ratio

// 16:9 ratio
const VIDEO_DIMENSIONS = {
  height: 180,
  width: 320,
}

const container = {
  height: VIDEO_DIMENSIONS.height,
  width: VIDEO_DIMENSIONS.width,
}

const DEFAULT_SETTINGS: MediaTrackConstraints = {
  aspectRatio: 16 / 9,
  facingMode: 'environment',
  // @ts-expect-error - this is a valid setting
  focusMode: 'continuous',
  frameRate: { max: 30 },
  torch: false,
  zoom: { ideal: 1 },
}

export const CAPTURE_OPTIONS = {
  audio: false,
  video: { ...DEFAULT_SETTINGS },
} as MediaStreamConstraints

export interface IBScan {
  onCapture: (code: string) => void
  scanRate?: number
}

interface ICapabilities {
  aspectRatio: {
    max: number
    min: number
  }
  deviceId: string
  facingMode: string[]
  focusDistance: {
    min: number
  }
  frameRate: {
    max: number
    min: number
  }
  groupId: string
  height: {
    max: number
    min: number
  }
  torch: boolean
  whiteBalanceMode: string[]
  width: {
    max: number
    min: number
  }
  zoom: {
    max: number
    min: number
  }
}

export const BScan: FC<IBScan> = ({ onCapture, scanRate = 100 }) => {
  const { isMobile } = useScreenSize()
  const canvasReference = useRef<HTMLCanvasElement>(null)
  const videoReference = useRef<HTMLVideoElement>(null)
  const timestamp = useRef(0)
  const [stream, setStream] = useState<MediaStream>()
  const [torch, setTorch] = useState<boolean>(false)
  const [zoom, setZoom] = useState<number>(1)
  const [capabilities, setCapabilities] = useState<ICapabilities>()
  const [code, setCode] = useState<string>()
  const [successfulScans, setSuccessfulScans] = useState<number>(0)
  const [scannerStatus, setScannerStatus] = useState<
    'idle' | 'initializing' | 'scanning' | 'unsupported'
  >('idle')
  const [cameraPermission, setCameraPermission] = useState<
    'denied' | 'granted' | 'prompt' | 'unknown'
  >('unknown')

  const ZbarResults: string[] = []
  const ZxingResults: string[] = []

  const setCodeCallback = useCallback((data: ICallback) => {
    if (data.library === 'zbar') ZbarResults.push(data.raw)

    if (data.library === 'zxing') ZxingResults.push(data.raw)

    const scans =
      ZbarResults.length > ZxingResults.length
        ? ZbarResults.length
        : ZxingResults.length
    setSuccessfulScans(scans)

    if (ZbarResults.length > 10 || ZxingResults.length > 10) {
      console.log('got data', ZbarResults, ZxingResults)
      const winner = findMostFrequent([...ZbarResults, ...ZxingResults])
      console.log('winner', winner)
      if (winner) setCode(winner)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [zbarWorker, zxingWorker] = useQr(setCodeCallback)

  const updateStreamSettings = useCallback(async () => {
    if (!stream) return

    const track = stream.getVideoTracks()[0]
    try {
      await track.applyConstraints({
        ...DEFAULT_SETTINGS,
        height: {
          ideal: isMobile ? VIDEO_DIMENSIONS.width : VIDEO_DIMENSIONS.height,
        },
        // @ts-expect-error - this is a valid setting
        torch,
        width: {
          ideal: isMobile ? VIDEO_DIMENSIONS.height : VIDEO_DIMENSIONS.width,
        },
        zoom: { ideal: zoom },
      })
    } catch {
      console.info('Torch not supported on this device')
    }
  }, [isMobile, stream, torch, zoom])

  const stopScanner = useCallback(async () => {
    if (!stream) return

    stream.getVideoTracks()[0].stop()
    videoReference.current?.pause()
    setScannerStatus('idle')
  }, [stream])

  useEffect(() => {
    if (!videoReference.current || !code) return

    if (!/^\d+$/.test(code)) return

    void stopScanner()
    onCapture(code)
  }, [code, onCapture, stopScanner, stream])

  const checkPermission = useCallback(() => {
    navigator.permissions
      // @ts-expect-error The camera request does exist!
      .query({ name: 'camera' })
      .then((permissionStatus) => {
        return setCameraPermission(permissionStatus.state)
      })
      .catch((error) => console.error(error))
  }, [])

  const recogniseCode = useCallback(
    (time: number) => {
      if (!canvasReference.current) return

      if (time - timestamp.current <= scanRate) return

      timestamp.current = time
      const context = canvasReference.current.getContext('2d', {
        willReadFrequently: true,
      })
      if (!context) return

      // 320/180
      const imageData = context.getImageData(42, 20, 220, 140)
      const dimensions = { height: imageData.height, width: imageData.width }

      const debugImage = document.querySelector('#debug') as HTMLImageElement
      if (debugImage) {
        const canvas = document.createElement('canvas')
        const canvasContext = canvas.getContext('2d')
        canvas.width = imageData.width
        canvas.height = imageData.height
        canvasContext?.putImageData(imageData, 0, 0)

        debugImage.src = canvas.toDataURL('image/png')
      }

      zbarWorker?.postMessage(dimensions, [])
      zbarWorker?.postMessage(imageData, [])

      zxingWorker?.postMessage(dimensions, [])
      zxingWorker?.postMessage(imageData, [])
    },
    [scanRate, zbarWorker, zxingWorker]
  )

  const tick = useCallback(
    (time: number) => {
      if (
        !videoReference.current ||
        !canvasReference.current ||
        videoReference.current.readyState !== 4
      ) return

      const context = canvasReference.current.getContext('2d', {
        willReadFrequently: true,
      })
      if (!context) return

      context.drawImage(
        videoReference.current,
        (videoReference.current.videoWidth - container.width) / 2,
        (videoReference.current.videoHeight - container.height) / 2,
        container.width,
        container.height,
        0,
        0,
        container.width,
        container.height
      )

      recogniseCode(time)

      if (!videoReference.current?.paused) requestAnimationFrame(tick)
    },
    [recogniseCode]
  )

  useEffect(checkPermission, [checkPermission])

  useEffect(() => {
    if (scannerStatus !== 'scanning') return

    requestAnimationFrame(tick)
  }, [scannerStatus, tick])

  useEffect(() => {
    void updateStreamSettings()
  }, [updateStreamSettings, zoom, torch])

  const startScanner = async () => {
    if (!videoReference.current) return

    // await videoReference.current?.play()
    setScannerStatus('initializing')

    // console.log('getting capture options')
    const currentStream =
      await navigator.mediaDevices.getUserMedia(CAPTURE_OPTIONS)
    // console.log('setting track')
    const track = currentStream.getVideoTracks()[0]
    const foundCapabilities = track.getCapabilities() as ICapabilities
    console.log('found capabilities', foundCapabilities)
    setCapabilities(foundCapabilities)
    setZoom(foundCapabilities.zoom?.max || 1)
    videoReference.current.srcObject = currentStream
    setStream(currentStream)
    // console.log('playing...')

    void videoReference.current.play()
    let checks = 0
    const interval = setInterval(() => {
      checks++
      if (videoReference.current?.readyState === 4) {
        setScannerStatus('scanning')
        updateStreamSettings()
        checkPermission()
        clearInterval(interval)
      }

      if (checks > 50) {
        console.log('bailing out...')
        clearInterval(interval)
        setScannerStatus('unsupported')
        stopScanner()
      }
    }, 100)
  }

  const renderActionButton = () => {
    switch (scannerStatus) {
      case 'scanning': {
        return (
          <div className={Styles.scannerActions}>
            <Group>
              <Button
                onClick={stopScanner}
                style={{ flexGrow: 1 }}
              >
                Stop Scanning
              </Button>
              {capabilities?.torch && (
                <Button onClick={() => setTorch(!torch)}>
                  {torch ? <IconBulbOff size={16} /> : <IconBulb size={16} />}
                </Button>
              )}
            </Group>
            {capabilities?.zoom && (
              <div className={Styles.slider}>
                <Text
                  mt="xl"
                  size="sm"
                >
                  Zoom
                </Text>
                <Slider
                  defaultValue={zoom}
                  label={(value) => `${value} x`}
                  marks={[
                    {
                      label: '1x',
                      value: 1,
                    },
                    {
                      label: `${capabilities.zoom.max} x`,
                      value: capabilities.zoom.max,
                    },
                  ]}
                  max={capabilities.zoom.max}
                  min={1}
                  onChangeEnd={setZoom}
                  size={'xl'}
                  step={0.1}
                />
              </div>
            )}
          </div>
        )
      }
      case 'idle': {
        return (
          <div className={Styles.scannerActions}>
            <Button
              fullWidth={true}
              onClick={startScanner}
            >
              Start Scanning
            </Button>
          </div>
        )
      }
      case 'initializing': {
        return (
          <div className={Styles.scannerActions}>
            <Button
              disabled
              fullWidth={true}
            >
              Initializing
            </Button>
          </div>
        )
      }
    }
  }

  return (
    <div className={Styles.scanner}>
      <div className={Styles.scannerContent}>
        <div className={Styles.scannerWrapper}>
          <div className={Styles.scannerContainer}>
            <IconCamera
              className={Styles.icon}
              size={64}
            />
            <video
              hidden={true}
              muted
              playsInline
              ref={videoReference}
            />
            <canvas
              className={Styles.cameraFeed}
              height={container.height}
              ref={canvasReference}
              width={container.width}
            />
            <div
              className={Styles.overlay}
              style={{
                outline: `4px solid ${scannerStatus === 'scanning' ? getOverlayColor(successfulScans) : '#777676'}`,
              }}
            />
          </div>
        </div>
        {renderActionButton()}
        {cameraPermission === 'prompt' && (
          <Alert
            color="green"
            icon={<IconInfoCircle />}
            title="Requesting permission"
            variant="outline"
          >
            Before we can start scanning, we need your permission to use the
            camera!
            <br />
            <br />A popup will show and ask for your permission to use the
            camera if you start scanning.
          </Alert>
        )}
        {cameraPermission === 'denied' && (
          <Alert
            color="red"
            icon={<IconAlertCircle />}
            title="Denied"
            variant="outline"
          >
            We are sorry, but we are unable to use your camera as you did not
            allow that 😭
          </Alert>
        )}
      </div>
    </div>
  )
}
