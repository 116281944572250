import {Header} from '#components/Header'
import {FC, ReactElement} from 'react'

import Styles from './styles.module.scss'

interface ISidebarAndMain {
  mainContent: ReactElement
}

export const TopNavAndMainContent: FC<ISidebarAndMain> = ({mainContent}) => {
  return (
    <div className={Styles.wrapper}>
      <Header/>
      <div className={Styles.content}>
        <div className={Styles.main}>{mainContent}</div>
      </div>
    </div>
  )
}
