import { Button, Container } from '@mantine/core'
import { useOs } from '@mantine/hooks'
import { useState } from 'react'
import { PwaPrompt } from 'react-ios-pwa-prompt-ts'

import Styles from './styles.module.scss'

export const HomeScreen = () => {
  const os = useOs()

  const [showPrompt, setShowPrompt] = useState(false)
  const isInstalled = window.matchMedia('(display-mode: standalone)').matches

  return (
    <div className={Styles.wrapper}>
      <Container>
        <div className={Styles.intro}>
          <h1>Board Game Library</h1>
          <p>Just a place to show off your board game library</p>
          <p>Maybe invite some friends to play?</p>
          {!isInstalled && os === 'ios' && (
            <Button onClick={() => setShowPrompt(true)}>
              Add to Home Screen
            </Button>
          )}
        </div>
      </Container>
      <PwaPrompt
        delay={0}
        isOpen={showPrompt}
        onClose={() => setShowPrompt(false)}
        skipStorageUpdate={true}
      />
    </div>
  )
}
