import image from '#assets/email.png'
import { Container, Image, SimpleGrid, Text, Title } from '@mantine/core'

import Styles from './styles.module.scss'

export const CheckMail = () => {
  return (
    <Container className={Styles.root}>
      <SimpleGrid
        cols={{ base: 1, sm: 2 }}
        spacing={{ base: 40, sm: 80 }}
      >
        <Image
          className={Styles.mobileImage}
          src={image}
        />
        <div>
          <Title className={Styles.title}>Please check your email...</Title>
          <Text
            c="dimmed"
            size="lg"
          >
            Thank you for signing up. We have sent an email with a verification
            link. Please click this link to continue :)
          </Text>
        </div>
        <Image
          className={Styles.desktopImage}
          src={image}
        />
      </SimpleGrid>
    </Container>
  )
}
