import { IGetBoardGameQuery, useGetBoardGameQuery } from '#/graphql/graphql'
import { ProductBox } from '#components/ProductBox'
import { Image, Skeleton } from '@mantine/core'
import { IconAlarm, IconOld, IconUsers } from '@tabler/icons-react'
import { FC } from 'react'
import 'react-lazy-load-image-component/src/effects/blur.css'
import { useDebounceCallback } from 'usehooks-ts'

import { Badge } from '../Badge'
import { CleanText } from '../CleanHTML'
import { ReadMore } from '../ReadMore'
import Styles from './styles.module.scss'

interface IBoardGameDetails {
  thumbnailUrl: string
  uuid: string
}

export const BoardGameDetails: FC<IBoardGameDetails> = ({
  thumbnailUrl,
  uuid,
}) => {
  const { data, error, isLoading, refetch } = useGetBoardGameQuery({
    uuid,
  })
  const debouncedRefetch = useDebounceCallback(refetch, 1000)
  const renderBadges = (
    boardGame: NonNullable<IGetBoardGameQuery['boardGame']>
  ) => {
    return (
      <div className={Styles.badges}>
        {boardGame.categories.map((category) => (
          <Badge
            key={category.category?.value}
            text={category.category?.value}
          />
        ))}
        {boardGame.mechanics.map((mechanic) => (
          <Badge
            key={mechanic.mechanic?.value}
            text={mechanic.mechanic?.value}
          />
        ))}
      </div>
    )
  }

  const renderSkeleton = () => (
    <div className={Styles.details}>
      <h1>
        <Skeleton
          height={'2em'}
          width={'85%'}
        />
      </h1>
      <div className={Styles.topPart}>
        <Image
          alt={'Cover image loading placeholder'}
          fit={'contain'}
          h={400}
          radius={'md'}
          src={thumbnailUrl}
          w={400}
        />
        <div className={Styles.playInfo}>
          <Skeleton
            height={'100%'}
            width={'100%'}
          />
        </div>
      </div>
      <Skeleton
        height={'1em'}
        radius="xl"
      />
      <Skeleton
        height={'1em'}
        mt={6}
        radius="xl"
      />
      <Skeleton
        height={'1em'}
        mt={6}
        radius="xl"
        width="70%"
      />
      <Skeleton
        height={'1em'}
        mt={6}
        radius="xl"
        width="20%"
      />
    </div>
  )

  const renderGame = (
    boardGame: NonNullable<IGetBoardGameQuery['boardGame']>
  ) => (
    <div className={Styles.details}>
      <h1>{boardGame.title}</h1>
      <div className={Styles.topPart}>
        <ProductBox
          imageUrl={boardGame.imageUrl ?? boardGame.thumbnailUrl}
          thumbUrl={boardGame.thumbnailUrl}
        />
        <div className={Styles.playInfo}>
          <div className={Styles.icons}>
            <div className={Styles.iconSet}>
              <IconUsers />{' '}
              <span>
                {boardGame.minPlayers} - {boardGame.maxPlayers} (
                {boardGame.recommendedPlayerCount}{' '}
                {boardGame.nonRecommendedPlayerCount &&
                  boardGame.nonRecommendedPlayerCount !==
                    boardGame.recommendedPlayerCount && (
                  <>
                      / <s>{boardGame.nonRecommendedPlayerCount}</s>
                  </>
                )}
                )
              </span>
            </div>
            <div className={Styles.iconSet}>
              <IconAlarm />
              <span>{boardGame.playTimeInMinutes} min.</span>
            </div>
            <div className={Styles.iconSet}>
              <IconOld /> <span>{boardGame.minAge}+</span>
            </div>
          </div>
          {renderBadges(boardGame)}
        </div>
      </div>

      <ReadMore
        id="description"
        text={CleanText(boardGame.description)}
      />
    </div>
  )

  if (error) debouncedRefetch()

  return isLoading || !data?.boardGame
    ? renderSkeleton()
    : renderGame(data.boardGame)
}
