import { Auth } from '#components/Auth'
import { createFileRoute } from '@tanstack/react-router'

interface AuthSearch {
  refreshToken: string
}

export const Route = createFileRoute('/_public/auth')({
  component: () => <Auth />,
  validateSearch: (search: Record<string, string>): AuthSearch => {
    return {
      refreshToken: search.refreshToken,
    }
  },
})
