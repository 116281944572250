import { useScreenSize } from '#contexts/ScreenSizeContext'
import { FC, useEffect, useState } from 'react'
import { v4 } from 'uuid'

import Styles from './styles.module.scss'

interface IProductBox {
  imageUrl: string
  thumbUrl: string
}

export const ProductBox: FC<IProductBox> = ({ imageUrl, thumbUrl }) => {
  const id = `product-box-${v4()}`
  const { isMobile } = useScreenSize()
  const width = isMobile ? 240 : 400
  const height = isMobile ? 240 : 400
  const [image, setImage] = useState<string>(thumbUrl)
  const [imageData, setImageData] = useState<{ height: number; width: number }>(
    { height: 0, width: 0 }
  )

  useEffect(() => {
    const preloadThumbImage = new Image()
    preloadThumbImage.addEventListener('load', function () {
      let ratioHeight = height
      let ratioWidth =
        (height / preloadThumbImage.height) * preloadThumbImage.width

      if (ratioWidth > width) {
        ratioWidth = width
        ratioHeight =
          (width / preloadThumbImage.width) * preloadThumbImage.height
      }

      setImageData({ height: ratioHeight, width: ratioWidth })
    })

    const preloadImage = new Image()
    preloadImage.addEventListener('load', function () {
      setImage(imageUrl)
    })
    preloadThumbImage.src = thumbUrl
    preloadImage.src = imageUrl
  }, [height, thumbUrl, width, imageUrl])

  return (
    <>
      <div
        className={Styles.productBox}
        style={{ height, opacity: 1, width }}
      >
        <div
          className={Styles.productImage}
          id={id}
          style={{ height: imageData.height, width: imageData.width }}
        />
        <style
          dangerouslySetInnerHTML={{
            __html: `
            #${id}{
              background-image: url("${image}"); 
            }
            #${id}::before {
              content: "";
              filter: brightness(70%);
              height: 100%;
              left: 0;
              position: absolute;
              top: 0;
              transform: rotateY(90deg);
              transform-origin: 0 50%;
              width: 60px;
              background-image: url("${image}");
              background-repeat: no-repeat;
              background-size: cover;
              box-shadow: 0 10px 20px rgb(0 0 0 / 0.1);
            }
          `,
          }}
        ></style>
      </div>
    </>
  )
}
