import { ScrollArea } from '@mantine/core'
import { FC, ReactNode } from 'react'

import Styles from './styles.module.scss'

interface ISideNav {
  children?: ReactNode
}

export const SideNav: FC<ISideNav> = ({ children }) => {
  return (
    <nav className={Styles.navbar}>
      <ScrollArea className={Styles.links}>{children}</ScrollArea>
    </nav>
  )
}
