import { Avatar, Group, Menu, Text, UnstyledButton, rem } from '@mantine/core'
import { useSignOut, useUserData } from '@nhost/react'
import { IconChevronDown, IconLogout } from '@tabler/icons-react'
import { useRouter } from '@tanstack/react-router'
import cx from 'clsx'
import { FC, useState } from 'react'

import Styles from './styles.module.scss'

export const UserDrawer: FC = () => {
  const router = useRouter()
  const { signOut } = useSignOut()
  const [userMenuOpened, setUserMenuOpened] = useState(false)
  const user = useUserData()

  if (!user) return null

  const handleSignOut = async () => {
    await signOut()
    router.history.push('/')
  }

  return (
    <Menu
      onClose={() => setUserMenuOpened(false)}
      onOpen={() => setUserMenuOpened(true)}
      position="bottom-end"
      transitionProps={{ transition: 'pop-bottom-right' }}
      width={260}
      withinPortal
    >
      <Menu.Target>
        <UnstyledButton
          className={cx(Styles.user, { [Styles.userActive]: userMenuOpened })}
        >
          <Group gap={7}>
            <Avatar
              alt={user.displayName}
              radius="xl"
              size={20}
              src={user.avatarUrl}
            />
            <Text
              fw={500}
              lh={1}
              mr={3}
              size="sm"
            >
              {user.displayName}
            </Text>
            <IconChevronDown
              stroke={1.5}
              style={{ height: rem(12), width: rem(12) }}
            />
          </Group>
        </UnstyledButton>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Label>Settings</Menu.Label>
        <Menu.Item
          leftSection={
            <IconLogout
              stroke={1.5}
              style={{ height: rem(16), width: rem(16) }}
            />
          }
          onClick={handleSignOut}
        >
          Logout
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  )
}
