import { IGetBoardGamesQuery } from '#graphql/graphql'
import cx from 'clsx'
import { motion } from 'framer-motion'
import { FC } from 'react'
import { LazyLoadImage, ScrollPosition } from 'react-lazy-load-image-component'

import Styles from './styles.module.scss'

interface IBoardGamePreview {
  data: IGetBoardGamesQuery['boardGames'][0]
  itemHeight?: number
  onClick: (item: IGetBoardGamesQuery['boardGames'][0]) => void
  scrollPosition?: ScrollPosition
  selected?: boolean
}

export const BoardGamePreview: FC<IBoardGamePreview> = ({
  data,
  itemHeight = 150,
  onClick,
  scrollPosition,
  selected,
}) => {
  return (
    <motion.div
      className={cx(Styles.boardGamePreview, selected ? 'selected' : '')}
      data-id={data.uuid}
      layoutId={data.uuid}
      style={{ height: itemHeight }}
      whileHover={{
        scale: 1.2,
        transition: {
          damping: 20,
          duration: 0.2,
          stiffness: 500,
          type: 'spring',
        },
      }}
    >
      <LazyLoadImage
        alt={`cover images for ${data.title}`}
        effect="opacity"
        height={itemHeight}
        onClick={() => onClick(data)}
        scrollPosition={scrollPosition}
        src={data.thumbnailUrl}
        style={{
          borderRadius: 'var(--mantine-radius-md)',
          maxHeight: itemHeight,
        }}
      />
    </motion.div>
  )
}
