import { Loader } from '@mantine/core'
import { useNhostClient } from '@nhost/react'
import { useRouter, useSearch } from '@tanstack/react-router'
import { useCallback, useEffect, useState } from 'react'

export const Auth = () => {
  const router = useRouter()
  const { refreshToken } = useSearch({ from: '/_public/auth' })
  const nhost = useNhostClient()
  const [initialized, setInitialized] = useState(false)

  const login = useCallback(async () => {
    if (!refreshToken) router.history.push('/')

    await nhost.auth.refreshSession(refreshToken)
    router.history.push('/profile')
  }, [nhost.auth, refreshToken, router.history])

  useEffect(() => {
    if (initialized) return

    setInitialized(true)

    void login()
  }, [initialized, login, refreshToken])

  return (
    <div
      style={{
        alignItems: 'center',
        display: 'flex',
        flex: 1,
        flexGrow: 1,
        justifyContent: 'center',
      }}
    >
      <Loader />
    </div>
  )
}
