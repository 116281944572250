import { Container } from '@mantine/core'

import Styles from './styles.module.scss'

export const AboutScreen = () => {
  return (
    <Container className={Styles.about}>
      <h1>About Board Game Library 🌟</h1>
      <p>
        Your board games. Leveled up. Ever dreamt of turning your board game
        collection into an epic library of magical tomes? With Enchanted
        Library, that dream&apos;s a reality. 📚✨
      </p>
      <h2>What&apos;s the Magic? 🎩</h2>
      <p>
        Scan & Showcase: Zap your game&apos;s barcode with our app and boom –
        it&apos;s in your digital collection. Each game transforms into a
        mystical entry in your own enchanted library.
      </p>
      <h2>Share the Adventure 🚀</h2>
      <p>
        Got an awesome collection? Show it off! Share your library with friends,
        family, or the whole world.
      </p>
      <h2>Discover 👀</h2>
      <p>
        new games, make connections, and let the world see your epic taste in
        board games.
      </p>
      <h2>Why Wait? 🌈</h2>
      <p>
        Join us. Dive into a universe where your board games come alive, and
        your collection grows as you do. Be part of a community that gets it.
      </p>
      <p>Enchanted Library: Where every game is a new story waiting for you.</p>
    </Container>
  )
}
