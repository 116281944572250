import { NotFound } from '#components/NotFound'
import { MantineProvider } from '@mantine/core'
import '@mantine/core/styles.css'
import '@mantine/notifications/styles.css'
import { NhostProvider } from '@nhost/react'
import { RouterProvider, createRouter } from '@tanstack/react-router'
import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'

import { auth } from './lib/auth'
import { nhost } from './lib/nhost' // Import the generated route tree
import { ScreenSizeProvider } from '#contexts/ScreenSizeContext'
import '@mantine/carousel/styles.css'
import '@mantine/charts/styles.css'
import { Notifications } from '@mantine/notifications'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { registerSW } from 'virtual:pwa-register'

import { routeTree } from './routeTree.gen'
import './styles.scss'

registerSW({ immediate: true })

const queryClient = new QueryClient()

const router = createRouter({
  context: { auth },
  defaultNotFoundComponent: NotFound,
  routeTree,
})

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const rootElement = document.querySelector('#root')!
if (!rootElement.innerHTML) {
  const root = createRoot(rootElement)
  root.render(
    <StrictMode>
      <MantineProvider defaultColorScheme="auto">
        <ScreenSizeProvider>
          <NhostProvider nhost={nhost}>
            <QueryClientProvider client={queryClient}>
              <ReactQueryDevtools initialIsOpen={false} />
              <Notifications />
              <RouterProvider
                context={{ auth }}
                router={router}
              />
            </QueryClientProvider>
          </NhostProvider>
        </ScreenSizeProvider>
      </MantineProvider>
    </StrictMode>
  )
}
