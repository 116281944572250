import { scrubbedDisplayName } from '#lib/helpers'
import { Avatar, Group, UnstyledButton } from '@mantine/core'
import { IconDice6Filled } from '@tabler/icons-react'
import { useRouter } from '@tanstack/react-router'
import { FC } from 'react'

import Styles from './styles.module.scss'

export interface IUserCard {
  avatarUrl: string
  displayName: string
  gamesCount: number
  id: string
}

export const UserCard: FC<IUserCard> = ({
  avatarUrl,
  displayName,
  gamesCount,
  id,
}) => {
  const router = useRouter()
  const handleClick = () => {
    router.history.push(`/users/${id}`)
  }

  return (
    <UnstyledButton
      className={Styles.userCard}
      onClick={handleClick}
    >
      <Group wrap="nowrap">
        <Avatar
          className={Styles.avatar}
          radius="md"
          src={avatarUrl}
        />
        <div>
          <div className={Styles.name}>{scrubbedDisplayName(displayName)}</div>

          <Group
            gap={10}
            mt={3}
            wrap="nowrap"
          >
            <IconDice6Filled
              className={Styles.icon}
              stroke={1.5}
            />
            <div className={Styles.count}>{gamesCount}</div>
          </Group>
        </div>
      </Group>
    </UnstyledButton>
  )
}
