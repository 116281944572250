import { CheckMail } from '#components/CheckMail'
import { nhost } from '#lib/nhost'
import {
  Anchor,
  Button,
  Checkbox,
  Container,
  Group,
  Paper,
  PasswordInput,
  Stack,
  Text,
  TextInput,
} from '@mantine/core'
import { useForm } from '@mantine/form'
import { upperFirst, useToggle } from '@mantine/hooks'
import { useSignInEmailPassword, useSignUpEmailPassword } from '@nhost/react'
import { useRouter } from '@tanstack/react-router'
import { FC, useEffect } from 'react'

export const LoginScreen: FC = () => {
  const router = useRouter()
  const [type, toggle] = useToggle(['login', 'register'])
  const {
    error: signUpError,
    isLoading,
    needsEmailVerification: signUpNeedsEmailVerification,
    signUpEmailPassword,
  } = useSignUpEmailPassword()
  const { error, needsEmailVerification, signInEmailPassword } =
    useSignInEmailPassword()

  if (error || signUpError) console.error({ error, signUpError })

  const form = useForm({
    initialValues: {
      email: '',
      name: '',
      password: '',
      terms: false,
    },
    validate: {
      email: (value: string) =>
        /^\S+@\S+$/.test(value) ? null : 'Invalid email',
      password: (value: string) =>
        value.length <= 6
          ? 'Password should include at least 6 characters'
          : null,
    },
  })

  useEffect(() => {
    if (!error || error.status != 401 || form.errors.auth) return

    form.setFieldError('auth', 'Invalid email or password')
  }, [error, form])

  useEffect(() => {
    if (!needsEmailVerification || form.errors.emailVerification) return

    form.setFieldError(
      'emailVerification',
      'Please check your email for a verification link'
    )
  }, [needsEmailVerification, form])

  const redirectToProfile = () => {
    router.history.push('/profile')
  }
  const handleLogin = async () => {
    form.clearErrors()
    nhost.auth.onAuthStateChanged((_, session) => {
      if (session != null) redirectToProfile()
    })

    if (type == 'login') await signInEmailPassword(form.values.email, form.values.password)

    if (type == 'register') {
      await signUpEmailPassword(form.values.email, form.values.password, {
        displayName: form.values.name,
      })
    }
  }

  if (needsEmailVerification || signUpNeedsEmailVerification) return <CheckMail />

  return (
    <div
      style={{
        alignItems: 'center',
        backgroundImage: 'url(/background.png)',
        backgroundSize: 'cover',
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
      }}
    >
      <Container size={'xs'}>
        <Paper
          p="xl"
          radius="md"
          withBorder
        >
          <Text
            fw={500}
            size="lg"
          >
            Welcome, {type} with
          </Text>

          <form onSubmit={form.onSubmit(handleLogin)}>
            <Stack>
              {type === 'register' && (
                <TextInput
                  label="Name"
                  onChange={(event) =>
                    form.setFieldValue('name', event.currentTarget.value)
                  }
                  placeholder="Your name"
                  radius="md"
                  value={form.values.name}
                />
              )}

              <TextInput
                error={form.errors.email && 'Invalid email'}
                label="Email"
                onChange={(event) =>
                  form.setFieldValue('email', event.currentTarget.value)
                }
                placeholder="example@me.com"
                radius="md"
                required
                type={'email'}
                value={form.values.email}
              />

              <PasswordInput
                error={
                  form.errors.password &&
                  'Password should include at least 6 characters'
                }
                label="Password"
                onChange={(event) =>
                  form.setFieldValue('password', event.currentTarget.value)
                }
                placeholder="Your password"
                radius="md"
                required
                value={form.values.password}
              />

              {form.errors.auth && (
                <Text
                  c="red"
                  size="sm"
                >
                  {form.errors.auth}
                </Text>
              )}

              {form.errors.emailValidation && (
                <Text
                  c="red"
                  size="sm"
                >
                  Must verify email
                </Text>
              )}

              {type === 'register' && (
                <Checkbox
                  checked={form.values.terms}
                  label="I accept terms and conditions"
                  onChange={(event) =>
                    form.setFieldValue('terms', event.currentTarget.checked)
                  }
                />
              )}
            </Stack>

            <Group
              justify="space-between"
              mt="xl"
            >
              <Anchor
                c="dimmed"
                component="button"
                onClick={() => toggle()}
                size="xs"
                type="button"
              >
                {type === 'register'
                  ? 'Already have an account? Login'
                  : "Don't have an account? Register"}
              </Anchor>

              <Button
                disabled={isLoading}
                radius="xl"
                type="submit"
              >
                {upperFirst(type)}
              </Button>
            </Group>
          </form>
        </Paper>
      </Container>
    </div>
  )
}
