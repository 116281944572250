import { LibraryWithScrollTracking } from '#components/Library'
import { StatsBar } from '#components/StatsBar'
import { ScreenSizes, useScreenSize } from '#contexts/ScreenSizeContext'
import { useGetBoardGamesQuery } from '#graphql/graphql'
import { TopNavWithSidebarAndMain } from '#layouts/TopNavWithSidebarAndMain'
import { useUserData } from '@nhost/react'
import { createFileRoute } from '@tanstack/react-router'

const Profile = () => {
  const user = useUserData()
  const { screenSize } = useScreenSize()
  const screenSizeTooSmall = [ScreenSizes.SM, ScreenSizes.XS].includes(
    screenSize
  )
  const { data, error, isLoading, refetch } = useGetBoardGamesQuery({
    uuid: user?.id,
  })

  if (error) console.error(error)

  if (isLoading) return null

  return (
    <TopNavWithSidebarAndMain
      mainContent={
        <LibraryWithScrollTracking
          data={data}
          refetch={refetch}
        />
      }
      sidebarContent={screenSizeTooSmall ? null : <StatsBar />}
    />
  )
}
export const Route = createFileRoute('/_authenticated/profile')({
  component: Profile,
})
