import {
  ActionIcon,
  Burger,
  Container,
  Drawer,
  Group,
  useComputedColorScheme,
  useMantineColorScheme,
} from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { IconMoon, IconSun } from '@tabler/icons-react'
import { useRouter } from '@tanstack/react-router'
import cx from 'clsx'
import { useState } from 'react'

import { UserDrawer } from './Components/UserDrawer'
import classes from './styles.module.scss'

const links = [
  { label: 'Home', path: '/' },
  { label: 'About', path: '/about' },
  { label: 'Users', path: '/users' },
  { label: 'Profile', path: '/profile' },
]

export const Header = () => {
  const router = useRouter()
  const [opened, { close, toggle }] = useDisclosure(false)
  const [active, setActive] = useState(router.state.location.pathname)
  const { setColorScheme } = useMantineColorScheme()
  const computedColorScheme = useComputedColorScheme('dark', {
    getInitialValueInEffect: true,
  })

  const items = links.map((link) => (
    <a
      className={classes.link}
      data-active={active === link.path || undefined}
      href={link.path}
      key={link.label}
      onClick={(event) => {
        event.preventDefault()
        if (opened) toggle()

        router.history.push(link.path)
        setActive(link.path)
      }}
    >
      {link.label}
    </a>
  ))

  return (
    <header className={classes.header}>
      <Container
        className={classes.inner}
        fluid
      >
        <div>BoardGameLibrary</div>
        <Group
          gap={5}
          visibleFrom="xs"
        >
          {items}
        </Group>
        <Group gap={5}>
          <ActionIcon
            aria-label="Toggle color scheme"
            color={computedColorScheme === 'dark' ? 'yellow' : 'grey'}
            onClick={() =>
              setColorScheme(computedColorScheme === 'light' ? 'dark' : 'light')
            }
            variant={'filled'}
          >
            {computedColorScheme === 'dark' ? (
              <IconSun
                className={cx(classes.icon, classes.light)}
                stroke={1.5}
              />
            ) : (
              <IconMoon
                className={cx(classes.icon, classes.dark)}
                stroke={1.5}
              />
            )}
          </ActionIcon>
          <UserDrawer />
          <Burger
            hiddenFrom="xs"
            onClick={toggle}
            opened={opened}
            size="sm"
          />
        </Group>
      </Container>
      <Drawer
        hiddenFrom="sm"
        onClose={close}
        opened={opened}
        padding="md"
        radius="md"
        size="16em"
        title="Navigation"
        zIndex={1_000_000}
      >
        {items}
      </Drawer>
    </header>
  )
}
