import { Link } from '@tanstack/react-router'

export const LoggedOutScreen = () => {
  return (
    <div className="p-2">
      <h3>You are logged out!</h3>
      <Link to="/login">Login again</Link>
    </div>
  )
}
