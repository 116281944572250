import { TanStackRouterDevtools } from '#/components/TanStackRouterDevTools'
import { Outlet, createFileRoute } from '@tanstack/react-router'
import { Suspense } from 'react'

const Component = () => {
  return (
    <>
      <Outlet />
      <Suspense fallback={null}>
        <TanStackRouterDevtools />
      </Suspense>
    </>
  )
}

export const Route = createFileRoute('/_public')({
  component: Component,
})
