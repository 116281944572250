import { Badge as MantineBadge } from '@mantine/core'
import ColorHash from 'color-hash'
import { FC } from 'react'

interface IBadge {
  text?: string
}

export const Badge: FC<IBadge> = ({ text }) => {
  if (!text) return null

  return <MantineBadge color={new ColorHash().hex(text)}>{text}</MantineBadge>
}
