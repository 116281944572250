import {NhostSession} from '@nhost/react'

export interface Auth {
  nhost: NhostSession | null
  status: 'authenticated' | 'unauthenticated'
}

export const auth: Auth = {
  nhost: null,
  status: 'unauthenticated',
}
