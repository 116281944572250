import notFound from '#assets/not-found.png'
import { BScan } from '#components/BScan'
import { Maybe, useLookupGameQuery } from '#graphql/graphql'
import {
  Button,
  CloseButton,
  Divider,
  Image,
  Loader,
  TextInput,
} from '@mantine/core'
import { useForm } from '@mantine/form'
import { IconSearch } from '@tabler/icons-react'
import { FC, useState } from 'react'

import { SearchResults } from '../SearchResults'
import Styles from './styles.module.scss'

interface ISearch {
  mutationLoading: boolean
  onAdd: (id: Maybe<number> | undefined) => void
}

export const Search: FC<ISearch> = ({ mutationLoading, onAdd }) => {
  const [title, setTitle] = useState<string>('')
  const [barcode, setBarcode] = useState<null | string>(null)
  const { data, isFetching, isLoading } = useLookupGameQuery(
    { barcode, title },
    { enabled: title.length > 2 || !!barcode }
  )

  const form = useForm({
    initialValues: {
      title: '',
    },

    validate: {
      title: (value) =>
        value.length < 3 ? 'Title must have at least 3 letters' : null,
    },
  })
  const handleReset = () => {
    setBarcode(null)
    setTitle('')
  }
  const handleLookup = (values: { title: string }) => {
    setTitle(values.title)
  }

  if (data?.lookupGame?.results) {
    return (
      <SearchResults
        isLoading={mutationLoading}
        onSelect={onAdd}
        results={data.lookupGame.results}
      />
    )
  }

  if (
    !isLoading &&
    !isFetching &&
    !data?.lookupGame?.results &&
    (barcode || title)
  ) {
    return (
      <div>
        <h1>No Results found...</h1>
        <p>We could not find anything for {barcode ?? title}...</p>
        <Image
          alt={'no results found'}
          fit={'contain'}
          h={400}
          radius={'md'}
          src={notFound}
          w={400}
        />
        <Button
          fullWidth
          onClick={handleReset}
        >
          Lets try again!
        </Button>
      </div>
    )
  }

  console.log({ barcode, data, isLoading, title })

  if (isLoading) {
    return (
      <div className={Styles.loading}>
        <h1>Searching...</h1>
        <Loader />
      </div>
    )
  }

  return (
    <form onSubmit={form.onSubmit(handleLookup)}>
      <div className={Styles.actions}>
        <TextInput
          className={Styles.search}
          data-autofocus
          error={form.errors.title && 'what'}
          leftSection={<IconSearch size={16} />}
          placeholder={'title...'}
          rightSection={
            <CloseButton
              aria-label="Clear input"
              onClick={() => form.setFieldValue('title', '')}
              style={{ display: form.values.title ? undefined : 'none' }}
            />
          }
          {...form.getInputProps('title')}
        />
        <Button
          className={Styles.searchButton}
          loading={isLoading}
          type={'submit'}
        >
          Search!
        </Button>
      </div>
      <Divider
        label="or"
        labelPosition="center"
        my="xs"
      />
      <div className={Styles.actions}>
        <BScan onCapture={(code) => setBarcode(code)} />
      </div>
    </form>
  )
}
